import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { slugify } from '@tytapp/common/slugify';
import { debounce } from './utils';
import { ApiPermissionCheckResult, UsersApi, ApiSlug, SlugsApi } from '@tytapp/api';
import { SlugMetadata, SlugService } from '../slug.service';

export interface Identifiable {
    cfid?: string;
    uuid?: string;
    id?: string | number;
}

@Component({
    selector: 'tyt-slug-field',
    templateUrl: './slug-field.component.html',
    styleUrls: ['./slug-field.component.scss']
})
export class SlugFieldComponent {
    private usersApi = inject(UsersApi);
    private slugs = inject(SlugService);

    private _slug: string;
    invalidSlug = false;
    checkTimeout;
    checkingForConflicts = false;
    checkedSlug: string;
    conflictingSlugType: string;
    conflictingSlugMetadata: SlugMetadata;
    permission?: ApiPermissionCheckResult;
    conflictingSlug: ApiSlug;

    @Input() slugReplacement: string;
    @Output() slugReplacementChange = new EventEmitter<string>();

    invalidSlugReplacement = false;

    @Input() slugGenerator: () => string;
    @Input() scope: 'global' | 'local' | 'none' = 'global';
    @Input() generatorTooltip: string;
    @Input() disabled = false;
    @Input() self: Identifiable;
    @Input() selfType: string;
    @Output() isThereConflict = new EventEmitter<boolean>();
    @Output() slugChange = new EventEmitter<string>();
    @Output() validChange = new EventEmitter<boolean>();

    get valid() {
        return !this.invalidSlug || (this.slugReplacement && !this.invalidSlugReplacement);
    }

    @Input()
    set slug(value: string) {
        this._slug = value;
        this.validateSlug();
    }

    get slug() {
        return this._slug;
    }

    get isGeneratedSlug() {
        return this.slugGenerator() === this.slug;
    }

    setGeneratedSlug() {
        this.slug = this.slugGenerator();
        this.onInputSlug();
    }

    emitValidity() {
        this.isThereConflict.emit(this.invalidSlug && (!this.slugReplacement || this.invalidSlugReplacement));
    }

    onInputSlug() {
        this.slugChange.emit(this.slug);

        this.invalidSlug = false;
        this.checkingForConflicts = true;
        this.checkedSlug = undefined;

        clearTimeout(this.checkTimeout);
        this.checkTimeout = setTimeout(() => this.validateSlug(), 100);
    }

    get canResolveConflict() {
        return this.permission?.allowed;
    }

    slugMatchesContent(slug: ApiSlug, contentType: string, content: Identifiable) {
        if (!slug || !content)
            throw new Error(`Cannot determine match as either slug or content is missing`);

        if (slug.type !== contentType)
            return false;

        if (slug.id === content.id)
            return true;
        if (slug.uuid === (content.cfid ?? content.uuid))
            return true;
        if (slug.uuid === content.id) // Traditional CF entities use `id` for `cfid`, so we need to do this check
            return true;

        return false;
    }

    private isSelf(slug: ApiSlug) {
        return this.self && this.slugMatchesContent(slug, this.selfType, this.self);
    }

    async validateSlug() {
        this.checkingForConflicts = true;
        this.checkedSlug = undefined;
        if (!this.slug) {
            this.invalidSlug = false;
            this.checkingForConflicts = false;
            return;
        }

        let invalidSlug = false;
        let conflictingSlug: ApiSlug = undefined;
        const slug = slugify(this.slug);
        if (this.slug !== slug) {
            this.slug = slug;
            this.slugChange.emit(slug);
        }

        try {

            if (this.scope !== 'none') {
                let currentSlug = await this.slugs.resolve(slug, this.scope === 'local' ? this.selfType : 'global');
                if (!currentSlug)
                    return;

                if (this.isSelf(currentSlug) || (this.scope !== 'global' && currentSlug.type !== this.selfType))
                    return;

                conflictingSlug = currentSlug;
                invalidSlug = true;
            }

        } finally {
            this.invalidSlug = invalidSlug;
            this.conflictingSlug = conflictingSlug;
            this.checkingForConflicts = false;

            if (this.conflictingSlug) {
                this.conflictingSlugType = this.slugs.getFriendlyNameForType(this.conflictingSlug.type);
                this.conflictingSlugMetadata = await this.slugs.getMetadata(this.conflictingSlug);
            }

            if (this.invalidSlug) {
                this.checkedSlug = slug;
                this.permission = await this.callPermissionApi();
            }

            this.emitValidity();
        }
    }

    onInputSlugReplacement() {
        this.slugReplacementChange.next(this.slugReplacement);
        this.validateSlugReplacement();
    }

    async validateSlugReplacement() {
        const slug = slugify(this.slugReplacement);
        if (this.slugReplacement !== slug) {
            this.slugReplacement = slug;
            this.slugReplacementChange.next(this.slugReplacement);
        }

        let conflictingSlug = await this.slugs.resolve(slug);
        this.invalidSlugReplacement = !!conflictingSlug;

        this.emitValidity();
    }

    async callPermissionApi(): Promise<ApiPermissionCheckResult> {
        return this.usersApi.checkPermission({ subject_type: 'Slug', action: 'manage' }).toPromise();
    }

    static {
        SlugFieldComponent.prototype.validateSlug = debounce(SlugFieldComponent.prototype.validateSlug, 1000);
        SlugFieldComponent.prototype.validateSlugReplacement = debounce(SlugFieldComponent.prototype.validateSlugReplacement, 1000);
    }

}
